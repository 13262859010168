import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService
{
  [x: string]: any;
  uid = localStorage.getItem('uid')
  name = localStorage.getItem('name')
  baseUrl: any = '';
  translations: any[] = [];

  constructor( private http: HttpClient)
   {
    this.baseUrl = environment.baseURL;
    }

  public post(url: string, body: any): Promise<any>
  {


   // console.log("Post Request Received");
    return new Promise<any>((resolve, reject) =>
    {
      console.log('Post Request : ',this.baseUrl + url, body);
      this.http.post(this.baseUrl + url, body).subscribe((data) =>
      {
     //   this.user_log(url)
        console.log(data)
        resolve(data);
      }, error => {
        resolve(error);
      });
    });

  }

  public get(url: string): Promise<any>
  {

   console.log("Get Request Received");
    return new Promise<any>((resolve, reject) =>
    {
      console.log('Post Request : ',this.baseUrl + url);
      this.http.get(this.baseUrl + url).subscribe((data) =>
      {
       console.log(data)
       // this.user_log(url)
        resolve(data);
      },
      error =>
      {
        resolve(error);
      });
    });
  }

 async user_log(url)
  {
    let data = {
      data : url,
      uid  : this.uid,
      name : this.name
    }

    await this.http.post('http://localhost/scalex_delivery_data/post_insert_data.php?table=user_log&authToken=ppvtech123',data).subscribe((data_rt: any) =>
      {
        console.log(data_rt)
      })
    // await this.post('post_insert_data.php?table=user_loguser_log&authToken='+environment.authToken,data).then((data_rt: any) =>
    //   {
    //       console.log(data_rt,data)
    //   }).catch(error =>
    //   {
    //       this.toastrService.error('Something went wrong');
    //   });
  }
}
