import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './service/api.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'az-root',
  encapsulation: ViewEncapsulation.None,
  template:`<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  uid = localStorage.getItem('uid')
  password = localStorage.getItem('password')
  mail = localStorage.getItem('email')

  constructor(
    private router: Router,
    public api: ApiService,
    public toastrService: ToastrService,
  ) {
    this.initializeApp();
   // this.LoginStatusCheck()
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  initializeApp() {
  }

  async LoginStatusCheck()
  {
   await this.api.get('get_data.php?table=employee_list&find=uid&value='+this.uid+'&authToken=' + environment.authToken).then((data: any) => {

     if(data[0].email == this.mail && data[0].password == this.password && data[0].web_type ==1 && data[0].status == 1)
     {
          const date = new Date();
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');

         let current_time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

           const start = new Date(data[0].last_login).getTime();
            const end = new Date(current_time).getTime();

            const differenceMs = Math.abs(end - start); // Absolute value to handle order
            const hours_diff = Math.floor(differenceMs / (1000 * 60 * 60));
            const minutes_diff = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));
            const seconds_diff = Math.floor((differenceMs % (1000 * 60)) / 1000);

            if(hours_diff>24)
              {
                  localStorage.clear();
                  this.router.navigate(['/login']);
              }
     }
     else{
        localStorage.clear();
        this.router.navigate(['/login']);
     }

    }).catch(error => { this.toastrService.error('Service Data not loaded'); });
  }
}
